<template>
  <form-wizard
    color="#7367F0"
    :title="null"
    :subtitle="null"
    finish-button-text="Simpan"
    next-button-text="Lanjut"
    back-button-text="Sebelumnya"
    class="steps-transparent mb-3"
    @on-complete="confirmSubmit"
  >
    <tab-content lazy title="Informasi Produk" :before-change="validationFirst">
      <b-row cols-sm="1" cols-xl="2">
        <b-col>
          <validation-observer ref="firstForm" tag="form">
            <form-input :rules="{required:true}" v-model="formData.code" label="Kode"></form-input>
            <form-input v-model="formData.name" label="Nama / Alias"></form-input>
            <form-select-all :rules="{required:true}" v-model="item_category" label="Kategori Produk" endpoint="v1/item_categories/selectkat" nested></form-select-all>
            <form-select-all :rules="{required:true}" v-model="brand" label="Merek" endpoint="v1/brands/select2"></form-select-all>
            <form-textarea v-model="formData.description" label="Deskripsi"></form-textarea>
          </validation-observer>
        </b-col>
      </b-row>
    </tab-content>
    <tab-content lazy title="Variasi Produk" :before-change="validationSecond">
      <validation-observer ref="secondForm" tag="form">
        <b-row>
          <b-col sm="12" xl="6">
            <form-select-all :rules="{required:true}" @option:selected="colorSizeChanged" @option:deselected="colorSizeChanged" multiple v-model="color" label="Variasi Warna" endpoint="v1/colors/select2"></form-select-all>
            <form-select-all :rules="{required:true}" @option:selected="colorSizeChanged" @option:deselected="colorSizeChanged" multiple v-model="size" label="Variasi Ukuran" endpoint="v1/sizes/select2"></form-select-all>
          </b-col>
          <b-col sm="12">
            <b-row cols="5">
              <b-col>
                <form-number :rules="{required:true}" v-model="adds.sale_price" label="Harga Jual"></form-number>
              </b-col>
              <b-col>
                <form-number :rules="{required:true}" v-model="adds.purchase_price" label="Harga Beli"></form-number>
              </b-col>
              <b-col>
                <form-input placeholder="Kosongkan jika mengikuti default" v-model="adds.code" label="Barcode"></form-input>
              </b-col>
              <b-col>
                <b-button class="mt-2" variant="primary" @click="apply">Terapkan ke Semua</b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="12">
            <b-table-simple small fixed outlined>
              <b-thead>
                <b-tr>
                  <b-th>Warna</b-th>
                  <b-th>Ukuran</b-th>
                  <b-th>Harga Jual</b-th>
                  <b-th>Harga Beli</b-th>
                  <b-th>Kode Variasi (Barcode)</b-th>
                  <b-th>Kode Pabrik (Barcode)</b-th>
                  <b-th>Status Aktif</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(value, key) in details" :key="key">
                  <b-td v-if="rowInit(key)" :rowspan="rowSpan(key)">{{value.color}}</b-td>
                  <b-td>{{value.size}}</b-td>
                  <b-td>
                    <inline-input-number v-model="value.sale_price" is-required></inline-input-number>
                  </b-td>
                  <b-td>
                    <inline-input-number v-model="value.purchase_price" is-required></inline-input-number>
                  </b-td>
                  <b-td>
                    <inline-input v-model="value.code" is-required></inline-input>
                  </b-td>
                  <b-td>
                    <inline-input button-if-empty v-model="value.factory_barcode"></inline-input>
                  </b-td>
                  <b-td>
                    <b-form-checkbox :name="`checkbox${key}`" v-model="value.is_active" :value="1" :unchecked-value="0"></b-form-checkbox>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-col>
        </b-row>
      </validation-observer>
    </tab-content>
  </form-wizard>
</template>

<script>
import FormInput from '@/utils/components/FormInput.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import FormSelectAll from '@/utils/components/FormSelectAll.vue'
import FormTextarea from '@/utils/components/FormTextarea.vue'
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard
import {BTableSimple,BThead,BTr,BTh,BTbody,BTd,BFormCheckbox,BButton} from 'bootstrap-vue'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import FormNumber from '@/utils/components/FormNumber.vue'
import { ValidationObserver } from 'vee-validate'
import InlineInputNumber from '@/utils/components/InlineInputNumber.vue'
import InlineInput from '@/utils/components/InlineInput.vue'

export default {
  components:{
    FormWizard,
    TabContent,
    FormInput,
    FormSelectAll,
    FormTextarea,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BFormCheckbox,
    FormNumber,
    BButton,
    ValidationObserver,
    InlineInputNumber,
    InlineInput
  },
  data(){
    const formData = {
      code:'',
      name:'',
      description:'',
    }
    const adds = {
      sale_price: 0,
      purchase_price: 0,
      stock: 0,
      code: ''
    }
    return {
      formData,
      item_category: null,
      brand: null,
      color: [],
      size: [],
      detailsRaw:[],
      adds
    }
  },
  computed:{
    details(){
      return sortBy(this.detailsRaw,['id_color','size'])
    }
  },
  methods:{
    confirmSubmit(){
      this.confirmation(`formSubmitted`)
    },
    async formSubmitted(){
      const vm = this
      const fd = {
        ...vm.formData,
        id_category: vm.item_category.value,
        id_brand: vm.brand.value,
        item_variants: vm.details
      }
      try {
        await vm.$http.post(`v1/items/create`,fd)
        vm.notify(`Data Berhasil Disimpan`,`OK`)
        vm.$router.push({name:`items`})
      } catch (error) {
        vm.handleError(error)
      }
    },
    validationFirst(){
      const vm = this
      return new Promise((resolve,reject) => {
        vm.$refs.firstForm.validate().then(success=>{
          if(success) resolve(true)
          else reject()
        })
      })
    },
    validationSecond(){
      const vm = this
      return new Promise((resolve,reject) => {
        vm.$refs.secondForm.validate().then(success=>{
          if(success) resolve(true)
          else reject()
        })
      })
    },
    rowInit(key){
      const vm = this
      if(key<=0) return true
      else {
        const prev = vm.details[key-1]
        const current = vm.details[key]
        if(prev.id_color==current.id_color) return false
        else return true
      }
    },
    rowSpan(key){
      const vm = this
      const current = vm.details[key]
      const total = filter(vm.details,n => n.id_color == current.id_color).length
      return total
    },
    colorSizeChanged(){
      const vm = this
      const colors = vm.color
      const sizes = vm.size
      const data = []
      for (let i = 0; i < colors.length; i++) {
        const color = colors[i];
        for (let x = 0; x < sizes.length; x++) {
          const size = sizes[x];
          data.push({
            id_size: size.value,
            size: size.label,
            id_color: color.value,
            color: color.label,
            sale_price: 0,
            purchase_price: 0,
            stock: 0,
            code: `${vm.formData.code}${color.code}${size.code}`,
            factory_barcode:'',
            is_active: 1
          })
        }
      }
      vm.detailsRaw = data
    },
    apply(){
      const vm = this
      const adds = vm.adds
      for (let i = 0; i < vm.detailsRaw.length; i++) {
        const detail = vm.detailsRaw[i];
        if(detail.is_active) {
          detail.sale_price = adds.sale_price
          detail.purchase_price = adds.purchase_price
          detail.stock = adds.stock
          if (adds.code) {
            detail.code = `${vm.formData.code}${adds.code}${i+1}`
          }
        }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
</style>
